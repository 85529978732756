import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { normalize } from 'css/normalize';
import { DragController } from 'mixins/controllers/drag';
import { TableBodyCell } from 'types/sheet/table';

const category = 'rota-cell';

const IS_DRAGGABLE_USER = new Set(['sp', 'x', 'fza', 'tx', 'bt']);

@customElement(category)
class RotaCell extends LitElement {
  drag = new DragController(this);

  @property() col: string;
  @property() date: string;
  @property({ type: Object }) cell: TableBodyCell;

  constructor() {
    super();
    this.addEventListener('mouseenter', (e) => this._onMouseEnter(e));
    this.addEventListener('mouseleave', (e) => this._onMouseLeave(e));
  }

  protected render(): unknown {
    switch (this.col) {
      case 'user':
        return this.userCell();
      case 'userEmpty':
        return this.userCellEmpty();
      case 'userViewOnly':
        return this.userCellViewOnly();
      default:
        console.error('Not found', this.col);
    }
  }
  private userCell() {
    return this.cell.type.map((t) => {
      const type = t.value.toLowerCase();
      const draggable = IS_DRAGGABLE_USER.has(type);

      if (t.value === '---') {
        return html`<span class="disabled"></span>`;
      }

      // cannot use ```?draggable``` - must be string
      return html`
        <slot></slot>
        <span
          class="${t.style}"
          data-col="user"
          data-uid="${this.cell.value}"
          data-date="${this.date}"
          data-type="${t.value}"
          draggable="${draggable}"
          @dragstart="${this.drag.onDragStart}"
          @dragend="${this.drag.onDragEnd}"
        >
          ${t.key}
        </span>
      `;
    });
  }
  private userCellEmpty() {
    return html`
      <slot></slot>
      <span
        data-col="user"
        data-uid="${this.cell.value}"
        data-date="${this.date}"
        @dragover="${this.drag.onDragOver}"
        @dragenter="${this.drag.onDragEnter}"
        @dragleave="${this.drag.onDragLeave}"
        @drop="${this.drag.onDrop}"
      ></span>
    `;
  }
  private userCellViewOnly() {
    return this.cell.type?.map((t) => {
      if (t.value === '---') {
        return html`<span class="disabled"></span>`;
      } else {
        return html`<span class="${t.style}">${t.key}</span>`;
      }
    });
  }

  _onMouseEnter(e: Event) {
    if (this.cell.logs.length === 0) return;
    else if (this.querySelector('rota-tooltip')) return;

    const tooltip = document.createElement('rota-tooltip');
    tooltip.logs = this.cell.logs;
    this.appendChild(tooltip);
  }
  _onMouseLeave(e: Event) {
    const tooltip = this.querySelector('rota-tooltip');
    if (!tooltip) return;
    tooltip.fadeOut();
    return;
  }

  static styles = [
    normalize,
    css`
      :host {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
      }
      :host(:hover) rota-tooltip.visibleOLD {
        opacity: 1;
        transform: scale(1);
      }
      span {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
      }
      span:first-child {
        border-top: 3px solid transparent;
      }
      span:last-child {
        border-bottom: 3px solid transparent;
      }

      [draggable="true"] {
        cursor: grab;
      }

      .purple {
        background: rgb(126, 87, 194);
        color: #ede7f6;
      }
      .green {
        background: var(--color-hintergrund);
        color: hsl(120deg 13% 54%);
      }
      .blue {
        background: rgb(66, 165, 245);
        color: rgb(13, 71, 161);
      }
      .lightBlue {
        background: #81d4fa;
        color: #0288d1;
      }
      .teal {
        background: hsl(187deg 100% 42%);
      }
      .bold {
        font-weight: 700;
      }
      :host-context(.selected) span {
        border: 0;
      }
      :host-context(.recent) span {
        animation-name: colorAnimation;
        animation-duration: 2s;
        animation-timing-function: ease-in-out;
        animation-play-state: running;
      }
      @keyframes colorAnimation {
        0% { background: var(--red-5); color: #000; }
        33% { background: var(--red-2); color: #000; }
        66% { background: var(--red-5); color: #000; }
        100% { background: var(--red-2); color: #000; }
      }

      :host-context(.logs) span,
      :host-context(.highlight) span,
      :host-context(.count) span,
      :host-context(.changes) span,
      :host-context(.offline) span {
        background: inherit;
        color: inherit;
      }

      .warning {
        background: #ef9a9a !important;
        color: #480c0c !important;
        font-weight: 500;
      }
      .disabled {
        background: var(--highlight-blue);
      }

      .virtual {
        text-decoration: underline;
      }
    `,
  ];
}
declare global {
  interface HTMLElementTagNameMap {
    [category]: RotaCell;
  }
}