import idb from 'logic/idb';
import api from 'logic/api';
import { store } from 'helpers/components';
import { refreshUser } from 'controller/server';
import { tokenChannel } from 'logic/signin/bc-tokens';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

const ONE_MIN = 1000 * 60 * 1; // refresh one min before expiry

let timeoutID;

function unixToEpoche(timestamp: number) {
  return timestamp * 1000;
}

function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload);
};

/**
 * Check expiration of token
 */
export function validateToken(token?: string) {
  if (!token) return false;

  const jwt = parseJwt(token);

  if (Date.now() > unixToEpoche(jwt.exp)) return false;
  else if (jwt.aud !== 'diakovere.app') return false;
  else if (jwt.iss !== 'anaesthetics.app') return false;

  return jwt;
}

async function timeoutCallback() {
  console.log('access token expiration countdown callback');
  try {
    const user = await refreshUser();
    if (!user || user.error) throw new Error(user.code || user.message);

    sessionStorage.setItem('accessToken', user.accessToken);
    sessionStorage.setItem('refreshToken', user.refreshToken);
    tokenChannel.postMessage({ action: 'tokens', access: user.accessToken, refresh: user.refreshToken });

    store.dispatch('user', login());
  } catch (err) {
    console.error(err);
  }
}
function startTokenExpirationCountdown(exp: number) {
  const expireAt = unixToEpoche(exp);
  const expireIn = expireAt - Date.now() - ONE_MIN;

  console.warn('Token refresh in', Math.round((expireIn) / 1000 / 60) + ' min');

  if (timeoutID) clearTimeout(timeoutID);
  timeoutID = setTimeout(timeoutCallback, expireIn);
}

export function login() {
  const accessToken = sessionStorage.getItem('accessToken');
  if (!accessToken) return;

  const validatedToken = validateToken(accessToken);
  if (validatedToken === false) return;

  const { email, usr, name, first_name, role, base, hospital, absence } = validatedToken.data;
  const uid = validatedToken.sub;

  const auth = Object.freeze({ id: 'auth', uid, email, usr, name, first_name, role, base, hospital, absence });
  idb.set(auth, 'settings');

  if (sessionStorage.getItem('context') !== 'secondary') startTokenExpirationCountdown(validatedToken.exp);

  return { type: LOGIN, data: { uid, email, usr, name, first_name, role, base, hospital, absence } };
}

export async function logout() {
  clearTimeout(timeoutID);

  await api('get', 'auth/signout').catch((err) => console.error(err));

  await Promise.all([
    idb.delete('handles'),
    idb.delete('logs'),
    idb.delete('rota'),
    idb.delete('settings'),
    idb.delete('sync'),
    idb.delete('users'),
  ]);
  idb.close();

  if (navigator.credentials && navigator.credentials.preventSilentAccess) {
    await navigator.credentials.preventSilentAccess().catch((err) => err); // Not implemented error in iOS
  }

  localStorage.clear();
  sessionStorage.clear();

  window.location.href = '/';
}
