import { LOGIN, LOGOUT } from '../actions/user.js';
import { Absence } from './users.js';

export type Base = 'DKH' | 'DKF' | 'Kein';

export interface ProfileState {
  absence?: Absence[], // only those rotating should have hospital
  uid: string,
  name: string,
  email: string,
  role: string, // 'Admin', 'Benutzer',
  usr: string,
  base?: Base, // non-doctors do not have a base
  hospital?: string, // only those rotating should have hospital
}
const user = {
  absence: undefined,
  uid: null,
  name: null,
  email: null,
  role: null,
  usr: null,
  base: undefined,
  hospital: undefined,
};
export function userReducer(state = user, action: Record<string, string | ProfileState>) {
  switch (action.type) {
    case LOGIN:
      return Object.assign({}, state, action.data);
    case LOGOUT:
      return user;
    default:
      return state;
  }
}
