/**
 * v1
 */
import { LitElement, html, css } from 'lit';
import { customElement, state } from 'lit/decorators.js';

const category = 'app-notify';

const MESSAGE_TIMEOUT = 1000 * 10;

interface MessageOptions {
  category?: string;
  callback?: () => void;
  id?: string;
  timeout?: boolean;
}
interface ToastEvent extends CustomEvent {
  message: string;
  detail: unknown;
}
declare global {
  interface HTMLElementTagNameMap {
    [category]: AppNotify;
  }
}

@customElement(category)
export class AppNotify extends LitElement {
  @state() _messages = [];

  constructor() {
    super();

    this.addEventListener('toast', (e: ToastEvent) => {
      e.stopPropagation();
      this._displayMessage(e.message, e.detail);
    });
  }

  _onClick({ target }) {
    const { textContent, dataset } = target;
    switch (textContent) {
      case 'dismiss':
        return this._remove(dataset.id);
      case 'update':
        const [item] = this._messages.filter((item) => item.id === dataset.id);
        item.callback();
        return this._remove(dataset.id);
    }
  }

  _remove(id: string) {
    const messages = this._messages.filter((item) => item.id !== id);
    this._messages = messages;
  }

  _displayMessage(msg: string, opts: MessageOptions = {}) {
    // performance.now() not accurate enough in iOS due to Spectra
    const [uint32] = window.crypto.getRandomValues(new Uint32Array(1));

    // eslint-disable-next-line prettier/prettier
    let {
      category = null,
      callback = null,
      id = uint32.toString(16),
      timeout = true,
    } = opts;

    if (this._messages.find((item) => item.msg === msg)) return console.warn('duplicate', msg);

    this._messages = [...this._messages, { msg, category, id, callback }];

    if (typeof callback === 'function') timeout = false;
    else if (category === 'error') timeout = false;

    if (timeout) setTimeout(() => this._remove(id), MESSAGE_TIMEOUT);
  }

  // serviceWorker
  // worker/init.js
  // updateController
  new(msg: string, opts: MessageOptions = {}) {
    this._displayMessage(msg, opts);
  }

  renderButton(id: string, btns: string[]) {
    return btns.map((btn) => html`<button type="button" data-id="${id}" @click="${this._onClick}">${btn}</button>`);
  }
  renderMessages() {
    return this._messages.map((item) => {
      const btns = item.callback ? ['dismiss', 'update'] : ['dismiss'];
      return html`
        <div>
          <span>${item.msg}</span>
          ${this.renderButton(item.id, btns)}
        </div>
      `;
    });
  }
  render() {
    return html`
      <slot></slot>
      <main>
        <section>${this.renderMessages()}</section>
      </main>
    `;
  }
  static styles = [
    css`
      :host {
        display: block;
        height: 100%;
      }
      main {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0;
        overflow: visible;
        font-size: 12px;
      }
      section {
        position: fixed;
        left: 50%;
        bottom: 24px;
        min-width: 344px;
        margin-left: -172px;
        z-index: 100;
        cursor: default;
      }
      divOLD {
        background: #2a2a2a;
      }
      div {
        display: flex;
        box-sizing: border-box;
        background: #1a73e8;
        border-radius: 2px;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
        color: #eee;
        transform-origin: center;
        will-change: transform;
        animation: popin 0.3s ease 1 forwards;
        margin: 5px 0;
      }
      span {
        flex: 1 1 auto;
        padding: 16px;
        font-size: 100%;
        width: 100%;
      }
      button {
        position: relative;
        flex: 0 1 auto;
        padding: 8px;
        height: 36px;
        margin: auto 8px auto -8px;
        min-width: 6em;
        background: none;
        border: none;
        border-radius: 3px;
        color: #90c7ee;
        font-weight: inherit;
        letter-spacing: 0.05em;
        font-size: 100%;
        text-transform: uppercase;
        text-align: center;
        cursor: pointer;
        overflow: hidden;
        transition: background-color 0.2s ease;
        outline: none;
      }
      @keyframes popin {
        0% {
          opacity: 0;
          transform: scale(0.5);
        }
      }
      @media (max-width: 400px) {
        section {
          min-width: unset;
          width: 100%;
          bottom: 49px;
          left: 0;
          margin-left: 0;
        }
        div {
          margin: 0;
          border-radius: 0;
        }
      }
      @media print {
        main {
          display: none;
        }
      }
    `,
  ];
}
