import { Base } from 'state/reducers/user';
import { ADD_USERS } from '../actions/users';

export interface ServerUser {
  absence?: Absence[];
  base: Base;
  first_name: string;
  hospital: Record<Year, Hospital[]>; // deprecate
  id: string;
  level: string;
  name: string;
  oncalls: string[];
  part_time: string[];
  leave?: Record<Year, LeaveValues>;
  role: string;
  upd: number;
}
export interface Absence {
  id: string;
  type: AbsenceCategory;
  start: string;
  end: string;
}
export type AbsenceCategory = 'DKH'|'DKF'|'DKA'|'---'|'HOS'|'EZ';

type Year = number;

export type Hospital = 'DKH'|'DKF'|'DKA'|'---';

export interface LeaveValues {
  age: number;
  anniversary: number;
  rest: number;
  night: number;
  allowed: number;
  total?: number; // only for summary, is not stored
}


export interface UserState extends ServerUser {
  displayName: string;
  hhd: boolean;
}

const users: UserState[] = [];

export function usersReducer(state = users, action): UserState[] {
  switch (action.type) {
    case ADD_USERS:
      return action.users;
    default:
      return state;
  }
}
