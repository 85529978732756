import { ReactiveController, ReactiveControllerHost } from 'lit';

import idb from 'logic/idb.js';
import timely from 'logic/timely';
import api from 'logic/api';

import { consoleCss, debounce } from 'helpers/utils';
import { store } from 'helpers/components';

import { UPDATE_IDB_STORE } from '../state/actions/idb';
import { addUsers } from '../state/actions/users';
import { UserRota } from 'types/server/rota';
import { server } from 'logic/settings';
import { aesGcmDecrypt, importKey } from 'logic/encrypt';
import { CLIENT_PRIVATE_KEY_NAME } from 'config/rota';

// key to decrypt data on cloud server
const PUBLIC_KEY_DKH = `-----BEGIN PUBLIC KEY-----
MFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAEaaAY0TyTzyk6z3tux1YWq+ZXdc7ap67v4ka5Y2xSrZxFYh23iIP+SbskFXE4Piess9rrr2Cy51nD9ll6MVFUcw==
-----END PUBLIC KEY-----`;

export interface RotaIndexedDB {
  bz: number;
  id: string;
  month: number;
  note: null
  rota: UserRota;
  timestamp: number;
  upd: number;
  year: number;
}

export class SyncController implements ReactiveController {
  host: ReactiveControllerHost;

  start: () => void;

  constructor(host: ReactiveControllerHost) {
    this.host = host;
    host.addController(this);

    this.start = debounce(this.startSync, 500);
  }
  hostConnected() {
  }
  hostDisconnected() {
  }
  async startSync() {
    console.log('%c%s', consoleCss('sync'), 'sync', 'starting');

    const updates = await idb.get('upd', 'settings');

    // rota needed for rota and template buttons which use idb rota
    // views don't use idb (for now), directly access server EXCEPT HOME AND DIENSTE
    const colToSync = ['rota', 'users'] as const;
    for (const col of colToSync) {
      const lastUpdated = updates[col] ? updates[col] : 0;

      let data = await api('get', 'sync', `${col}/?upd=${lastUpdated}`).catch((err) => console.error(err));
      if (!data) continue;

      if (col === 'rota') {
        data = data.map((item: RotaIndexedDB) => {
          item.timestamp = timely(item.id).timestamp();
          return item;
        });

        //TODO: server/service/app = mobile-cloud | desktop-intranet
        if (server === 'cloud') {
          console.error('cloud');

          const pem = localStorage.getItem(CLIENT_PRIVATE_KEY_NAME);
          if (!pem) {
            console.error('no private key');
            continue;
          }

          const pub = sessionStorage.getItem('publicKey');
          const secret = sessionStorage.getItem('encryptedKey');
          console.log(pub, secret);


          const userPrivateKey = await importKey(pem, 'PRIVATE').catch((err) => console.error(err));
          const userPublicKey = await importKey(pub, 'PUBLIC').catch((err) => console.error(err));


          const derivedKey = await window.crypto.subtle.deriveKey(
            { name: 'ECDH', public: userPublicKey },
            userPrivateKey,
            { name: 'AES-GCM', length: 256 },
            true,
            ['encrypt', 'decrypt']
          ).catch((err) => console.error(err));

          const private_key_dkh = await aesGcmDecrypt(secret, derivedKey).catch((err) => console.error(err));
          console.warn('private_key_dkh', private_key_dkh);

          /**
           * Private key is used by all to decrypt data
           * Best way:
           * Encrypt private key
           *
           * USER
           * DONE Click on Generate Private Key
           * DONE Diakovere generates DIAKOVERE_PRIVATE (NETWORK DRIVE) + DIAKOVERE_PUBLIC keys (proxy database)
           * DONE Send over user profile to proxy
           *
           * Log in to proxy
           * Client generate
           * + USER_PRIVATE (save on device - localStorage for now, then privateFileSystem)
           * + USER_PUBLIC (download) keys via Web Share
           *
           * Diakovere:
           * Save USER_PUBLIC to NETWORK DRIVE
           * Create encrypted private key
           * This will encrypt the DIENSTPLAN PRIVAT key (Tagesplan folder - zertifikat) using DIAKOVERE PRIVATE and USER PUBLIC (BOTH ON USER'S NETWORK DRIVE)
           * Save ENCRYPTED KEY to proxy database user profile
           *
           * Client:
           * Download ENCRYPTED KEY from proxy
           * Decrypt using PRIVAT USER KEY on device and DIAKOVERE PUBLIC KEY from proxy database
           * Decrypted key is used to decrypt rota
           *
           *
           *
           * Diakovere => User
           * privateKey DIAKOVERE, publicKey CLIENT (must be uploaded => will generate encrypted secret)
           *
           * Proxy => User
           * publicKey DIAKOVERE (privateKey on CLIENT DEVICE) => both will decrypt secret
           */
          const privateKey = await importKey(private_key_dkh, 'PRIVATE').catch((err) => console.error(err));
          const publicKey = await importKey(PUBLIC_KEY_DKH, 'PUBLIC').catch((err) => console.error(err));

          const derivedServerKey = await window.crypto.subtle.deriveKey(
            { name: 'ECDH', public: publicKey },
            privateKey,
            { name: 'AES-GCM', length: 256 },
            true,
            ['encrypt', 'decrypt']
          ).catch((err) => console.error(err));

          const promises = data.map((i) => aesGcmDecrypt(i.rota, derivedServerKey));
          const resolved = await Promise.all(promises).catch((err) => console.error(err));

          data = data.map((item, i) => {
            const rota = JSON.parse(resolved[i]);
            return Object.assign({}, item, { rota });
          });

          console.warn({data});
        }
      }

      if (data.length) {
        store.dispatch('idb', { type: UPDATE_IDB_STORE, store: { [col]: Date.now() } });

        if (col === 'users') {
          for (const user of data) {
            if (user.deleted) idb.delete(col, user.id);
          }
          data = data.filter((u) => !u.deleted);
          await idb.add(data, col);
          const users = await idb.getAll('users');
          // const users = fakeName();
          store.dispatch('users', addUsers(users));
          await idb.set({ id: 'upd', users: Math.round(Date.now() / 1000) }, 'settings');
        } else {
          await idb.add(data, col);
          const { upd } = data[data.length - 1];
          await idb.set({ id: 'upd', [col]: upd }, 'settings');
          //console.log('upd =>', timely(upd * 1000).timeZone('Europe/Berlin').format('DateTime'));
          //console.warn('getDocument', col, data);
        }
      }
    }
  }
}

/*
const fakeNames = [
  'Massey',
  'Contreras',
  'Roach',
  'Booker',
  'Burgess',
  'Oconnor',
  'Ferguson',
  'Whitaker',
  'Haynes',
  'Marquez',
  'Owen',
  'Irwin',
  'Blanchard',
  'Reed',
  'Ayala',
  'Wheeler',
  'Combs',
  'Odom',
  'Patterson',
  'Solomon',
  'Roberts',
  'Murillo',
  'Kent',
  'Pennington',
  'Madden',
  'Bentley',
  'Guerra',
  'Simon',
  'Walton',
  'Dodson',
  'David',
  'Williams',
  'Maxwell',
  'Farley',
  'Wilson',
  'Prince',
  'Tate',
  'Villanueva',
  'Travis',
  'Burns',
  'Holmes',
  'Pacheco',
  'Becker',
  'Weber',
  'Hester',
  'Mooney',
  'Graves',
  'Joyce',
  'Rowland',
  'Spence',
];
function fakeName() {
  return fakeNames[Math.floor(Math.random() * fakeNames.length)];
}
*/