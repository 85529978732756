import { UPDATE_APP_SETTINGS } from '../../state/actions/app';
// when using media="print", ensure css is preloaded
/**
 * Removed indexedDb as takes long time to load on init, use localStorage instead
 */

const PREFERS_COLOR_SCHEME = 'prefers-color-scheme';
const MEDIA_QUERY_DARK = `(${PREFERS_COLOR_SCHEME}:dark)`;
const MEDIA_QUERY_LIGHT = `(${PREFERS_COLOR_SCHEME}:light)`;
const ALL = 'all';
const NOT_ALL = 'not all';

const store = $('app-state');
const dark = document.getElementById('dark-css');
const light = document.getElementById('light-css');

const schemes = new Set(['light', 'dark']);

const category = 'color-scheme';

// firefox
light.removeAttribute('onload');
dark.removeAttribute('onload');

function updateColorScheme(colorScheme) {
  console.log(colorScheme);
  if (colorScheme === 'light') {
    light.media = ALL;
    light.disabled = false;
    dark.media = NOT_ALL;
    dark.disabled = true;
  } else {
    dark.media = ALL;
    dark.disabled = false;
    light.media = NOT_ALL;
    light.disabled = true;
  }
  store.dispatch('app', { type: UPDATE_APP_SETTINGS, state: { colorScheme } });
}
export function setColorScheme(colorScheme) {
  updateColorScheme(colorScheme);
  localStorage.setItem(category, colorScheme);
}

export async function loadColorScheme() {
  let mode = 'light';
  if (window.matchMedia('(prefers-color-scheme)').media !== NOT_ALL) {
    //console.log('🎉 prefers-color-scheme supported');
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      //elog('system preference is dark mode', { category: 'darkmode' });
    }
  }
  // need to support `media="(prefers-color-scheme: dark)"` (with space)
  // and `media="(prefers-color-scheme:dark)"` (without space)

  const supportsColorScheme = window.matchMedia(MEDIA_QUERY_DARK).media !== NOT_ALL;
  if (supportsColorScheme) {
    window.matchMedia(MEDIA_QUERY_DARK).addListener(({ matches }) => {
      if (window.matchMedia('print')) return;

      const colorScheme = matches ? 'dark' : 'light';
      console.log(`event listener changed colour scheme to ${colorScheme}`, { category });
      updateColorScheme(colorScheme);
    });
    mode = window.matchMedia(MEDIA_QUERY_LIGHT).matches ? 'light' : 'dark';
  }

  const colorScheme = localStorage.getItem(category);
  if (colorScheme && schemes.has(colorScheme)) {
    mode = colorScheme;
    console.log(`${colorScheme} mode (user preference)`, { category });
  } else if (supportsColorScheme) {
    console.log(`${colorScheme} mode (system preference)`, { category });
  }

  updateColorScheme(mode);
}
