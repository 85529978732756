import { LitElement, html, css } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { router } from 'helpers/components';

import { normalize } from '../css/normalize.js';
import { Route, routes } from 'logic/settings.js';
import { StateController } from 'controller/state.js';
import { State } from 'state/reducers.js';

const category = 'app-tapbar';

@customElement(category)
class AppTapbar extends LitElement {
  @state() route: {};
  @state() _tapbar: Route[];
  @state() _display: boolean;

  constructor() {
    super();
    new StateController(this);

    this._tapbar = routes.filter((route) => route.display.includes('tapbar'));
    console.log(this._tapbar);
  }
  stateChanged(state: State) {
    this.route = state.route.view;
  }
  updated(props) {
    if (props.has('route')) {
      this._display = +this.route.level === 0 ? false : true;
    }
  }

  _onClick({ target }) {
    if (target.dataset.route) {
      router.go(target.dataset.route);
    }
  }


  renderTapbar() {
    return this._tapbar.map((a) => {
      return html`
        <button type="button" data-route="${a.href}" class=${a.href === location.pathname ? 'selected' : 'default'}>
          <svg-icon>${a.attrs.icon}</svg-icon>
        </button>
      `;
    });
  }
  render() {
    //if (!this.showNavbar) return;
    return html`<nav @click="${this._onClick}">${this.renderTapbar()}</nav>`;
  }
  static get styles() {
    return [
      normalize,
      css`
        :host {
          position: fixed;
          bottom: 0;
          right: 0;
          width: 100%;
          pointer-events: none;
          z-index: 2;
        }
        nav {
          display: none;
          justify-content: space-evenly;
          border-top: 1px solid var(--shell-border-color);
          background-color: var(--shell-surface);
          color: var(--on-background);
          pointer-events: auto;
        }
        button {
          position: relative;
          padding: 12px;
          line-height: 0;
        }
        button::before,
        button::after {
          content: '';
          position: absolute;
          pointer-events: none;
          border-radius: 50%;
          opacity: 0;
          transition: opacity 15ms linear, background-color 15ms linear;
          z-index: 1;
          top: calc(50% - 50%);
          left: calc(50% - 50%);
          width: 100%;
          height: 100%;
          background-color: #fff;
        }
        button:hover::before {
          opacity: 0.08;
        }
        button:active::after,
        button:focus::before {
          transition-duration: 75ms;
          opacity: 0.24;
        }
        button:focus::after {
          transition: opacity 150ms linear;
        }
        button svg-icon {
          pointer-events: none;
        }
        @media screen and (max-width: 650px) {
          nav {
            display: flex;
          }
        }
      `,
    ];
  }
}
declare global {
  interface HTMLElementTagNameMap {
    [category]: AppTapbar;
  }
}
