import api from '../logic/api';
import timely from 'logic/timely';
import { ServerRow, UserRota } from 'types/server/rota';
import { Auth } from 'types/server/auth';

export async function getRota(col: string, year: string, month: string, upd = 0) {
  return await api('get', col, `?year=${year}&month=${month}&upd=${upd}`).catch((err) => console.log(err));
}
export async function getRotaBz(col: string, bz: number, upd = 0) {
  return await api('get', col, `?bz=${bz}&upd=${upd}`).catch((err) => console.error(err));
}
export async function get(col: string, date?: string) {
  return await api('get', col, date).catch((err) => console.error(err));
}
export async function getBound(col: string, field: string, lower: string, upper: string, upd = 0) {
  lower = timely(lower).format();
  upper = timely(upper).format();
  return await api('get', col, `bound/?field=${field}&lower=${lower}&upper=${upper}&upd=${upd}`).catch((err) =>
    console.error(err)
  );
}
export async function getAllTemplate() {
  return await api('get', 'template', 'all').catch((err) =>
    console.error(err)
  );
}
/**
 * Will get all entries where userRota has dayInLieu = null
 * Will search in rota and template
 */
export async function getDayInLieu(uid: string) {
  return await api('get', 'template', `dayInLieu/?uid=${uid}`).catch((err) =>
    console.error(err)
  );
}

export async function getAnnualLeave(year: number, upd: number): Promise<ServerRow[]> {
  return await api('get', 'leave', `?year=${year}&upd=${upd}`).catch((err) => console.error(err));
}

export interface UserLeave {
  year: number;
  month: number;
  id: string;
  rota: UserRota;
}
export async function getLeave(year: number, uid: string = null, upd: number = 0) {
  try {
    if (uid && upd > 0) {
      return await api('get', 'leave', `?uid=${uid}&year=${year}&upd=${upd}`) as UserLeave[];
    } else if (uid) {
      return await api('get', 'leave', `?uid=${uid}&year=${year}`) as UserLeave[];
    }
  } catch (err) {
    console.error(err);
  }
}
export async function getAllocations(year: string) {
  return await api('get', 'rota/allocations', `?year=${year}`).catch((err) => console.error(err));
}

export async function getLogs(date: string) {
  return await api('get', 'logs', `?date=${date}`).catch((err) => console.error(err));
}
export async function deleteLogs(date: string) {
  return await api('delete', 'logs', `?date=${date}`).catch((err) => console.error(err));
}

export async function updateLeaveSummary(id: string, leave: string, year: string) {
  try {
    if (!id) throw new Error('Missing data');

    const body = { leave, id, year };
    return await api('put', 'users', 'leave', { body }).catch((err) => elog(err));
  } catch (e) {
    console.log('Transaction failed', e);
  }
}
export async function updateUserNote(col: string, id: string, uid: string, note: string) {
  try {
    if (!id || !uid) throw new Error('Missing data');
    else if (!note) note = null;

    const body = {
      tasks: [{ date: id, uid, action: 'updateUserNote', note }],
    };
    return await api('post', col, '', { body }).catch((err) => elog(err));
  } catch (e) {
    console.log('Transaction failed', e);
  }
}
export async function updateTableSoll(col: string, date: string, soll: string) {
  try {
    if (!date) throw new Error('Missing data');
    else if (!soll) soll = null;

    const body = { date, soll };
    return await api('put', col, `table-soll`, { body }).catch((err) => elog(err));
  } catch (e) {
    console.log('Transaction failed', e);
  }
}
export async function updateTableNote(col: string, date: string, note: { content: string, highlight: boolean }) {
  try {
    if (!date) throw new Error('Missing data');
    else if (!note.content) note.content = null;

    const body = { date, note };
    return await api('put', col, `table-note`, { body }).catch((err) => elog(err));
  } catch (e) {
    console.log('Transaction failed', e);
  }
}
export async function txUpdateRota(col: string, tasks = []) {
  try {
    if (tasks.length === 0) return console.error('Task list is empty!', tasks);

    const body = { tasks };
    return await api('post', col, '', { body }).catch((err) => elog(err));
  } catch (e) {
    console.log('Transaction failed', e);
  }
}
export async function toggleHighlight(id: string, uid: string, _highlight: boolean) {
  try {
    if (!id || !uid) throw new Error('Missing data');

    const body = {
      tasks: [{ date: id, uid, action: 'toggleHighlight', _highlight }],
    };
    console.log(body.tasks[0]);
    return await api('post', 'template', '', { body }).catch((err) => elog(err));
  } catch (e) {
    console.log('Transaction failed', e);
  }
}

export async function getCloud(upd: string) {
  return await api('get', 'sync', `cloud/?upd=${upd}`).catch((err) => console.error(err));
}

export async function getUser(username: string, password: string): Promise<Auth> {
  return await api('post', 'auth', 'login', { body: { username, password } });
}
export async function refreshUser(): Promise<Auth> {
  return await api('get', 'auth', 'refresh', { auth: true });
}

/**
 * Updates specified id and col on server
 */
export async function set(id: string, body, col: string) {
  return await api('put', col, id, { body }).catch((err) => elog(err));
}
export async function updateUserId(id: string, newId: string) {
  return await api('put', 'users', 'id/' + id, { body: { id: newId } }).catch((err) => elog(err));
}
export async function updateUserHash(id: string, hash: string) {
  return await api('put', 'users', 'hash/' + id, { body: { hash } }).catch((err) => elog(err));
}

export async function createRotaFromTemplate(bz: number) {
  return await api('get', 'rota', `copy/?bz=${bz}`).catch((err) => elog(err));
}

export async function deleteRota(col: string, bz: number) {
  return await api('delete', col, `${bz}`).catch((err) => elog(err));
}

export async function getUserProfile() {
  return await api('get', 'users', 'current').catch((err) => console.log(err));
}

export async function createYear(year: string, body: unknown) {
  return await api('post', 'admin', `year/${year}`, { body }).catch((err) => console.error(err));
}
export async function adminArchiveTemplate(date: string) {
  return await api('post', 'admin', `archive/${date}`).catch((err) => console.error(err));
}

export async function exportBackup(table: string, range: string) {
  return await api('get', 'backup', `${table}/${range}`).catch((err) => console.log(err));
}
export async function importBackup(table: string, range: string, data: string) {
  return await api('post', 'backup', `${table}/${range}`, { body: { rows: data }}).catch((err) => console.log(err));
}
